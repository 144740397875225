import Alpine from 'alpinejs';
import ThemeStore from "@/Stores/ThemeStore.js";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Frontend/matchHeight.js';
import './Frontend/hashChange.js';
import './Frontend/renderScroll.js';
import './Frontend/clickable.js';
import './Frontend/debugbar.js';

window.ThemeStore = ThemeStore;

document.addEventListener('alpine:init', () => {
    AOS.init({
        duration: 200,
        easing: 'ease-in-out',
    });
})

window.Alpine = Alpine;
Alpine.start();






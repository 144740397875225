function renderScroll() {
    if(window.scrollY > 0) {
        document.body.hasAttribute('data-scrolled') || document.body.setAttribute('data-scrolled', '');
    } else {
        document.body.hasAttribute('data-scrolled') && document.body.removeAttribute('data-scrolled');
    }
}

window.addEventListener('resize', renderScroll);
window.addEventListener('load', renderScroll);
window.addEventListener('scroll', renderScroll);

renderScroll();

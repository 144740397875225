
function isNodeVisible(node) {
    if (!node) {
        return false;
    }

    const style = window.getComputedStyle(node);
    if (style.display === 'none' || style.visibility === 'hidden' || style.opacity === '0') {
        return false;
    }

    if (node.offsetWidth === 0 && node.offsetHeight === 0) {
        return false;
    }

    const rect = node.getBoundingClientRect();
    if (rect.width === 0 || rect.height === 0) {
        return false;
    }

    return true;
}

const render = () => {
    document.querySelectorAll('[data-match-height]').forEach(wrapper => {
        const nodes = [];
        const maxHeights = {};
        let initPostY = null;
        let perRow = 0;

        wrapper.childNodes.forEach(node => {
            if (node.nodeType === 1) {

                if(!isNodeVisible(node)) {
                    return;
                }

                if (initPostY === null) {
                    initPostY = node.offsetTop;
                }

                const children = {};

                node.querySelectorAll('[data-match-height-child]').forEach(child => {
                    maxHeights[child.dataset.matchHeightChild] = 0;
                    children[child.dataset.matchHeightChild] = child;
                    child.style.height = 'auto';
                });

                if (initPostY >= node.offsetTop) {
                    perRow++;
                }

                nodes.push({
                    wrapper: node,
                    children: children
                });
            }
        });

        for (let i = 0, c = nodes.length; i < c; i += perRow) {
            const maxHeight = {};
            for (const child of Object.keys(maxHeights)) {
                maxHeight[child] = 0;
            }

            for (let j = 0; j < perRow && i + j < c; ++j) {
                for (const child of Object.keys(maxHeights)) {
                    let height = nodes[i + j].children[child].offsetHeight;
                    if (height > maxHeight[child]) {
                        maxHeight[child] = height;
                    }
                }
            }

            for (let j = 0; j < perRow && i + j < c; ++j) {
                for (const child of Object.keys(maxHeights)) {
                    if (maxHeight[child]) {
                        nodes[i + j].children[child].style.height = maxHeight[child] + 'px';
                    }
                }
            }
        }
    });
};

let debounceTimeout;
const debounce = () => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(render, 100);
}

document.addEventListener('DOMContentLoaded', render);
window.addEventListener('load', debounce);
window.addEventListener('resize', debounce);
window.addEventListener('match-height', render);

window.matchHeightRender = render;

function handleHashChange(disableScroll) {
    const hash = window.location.hash;

    if (hash.endsWith('-plugin')) {
        const pluginName = hash.slice(1, hash.indexOf('-plugin'));
        const event = new CustomEvent('plugin-selected', {
            detail: {plugin: pluginName}
        });
        document.dispatchEvent(event);

        if (!disableScroll) {
            document.getElementById('plugins')?.scrollIntoView({behavior: 'smooth'});
        }
    }
}

window.addEventListener('hashchange', event => {
    event.preventDefault();
    handleHashChange(false);
});
handleHashChange(true);

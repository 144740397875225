if (document.querySelector("[data-clickable]")) {
    document.querySelectorAll("[data-clickable]").forEach((wrapper) => {
        const link = wrapper.querySelector("[data-wrapper-link]");

        wrapper.addEventListener("click", (event) => {
            window.location = link.href;
            event.preventDefault();
        });
    });
}
